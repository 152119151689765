'use strict';

function passwordResetModalCtrl($scope, profile, $uibModalInstance, toastr, singleUser, globalFunc,
                                supplierWebUserResetPassword, $rootScope , $timeout, $translate, adminSettings) {

  $scope.createNewPassword = createNewPassword;
  $scope.closeModal = closeModal;
  $scope.error = false;
  $scope.missMatchError = false;
  $scope.wrongFormatError = false;
  $scope.profile = profile;
  $scope.isV2FeatureEnabled = isV2FeatureEnabled;
  $scope.isMulticased = false;
  $scope.isNumbered = false;
  $scope.isSymboled = false;
  $scope.isWhitespaceStart = false;
  $scope.isWhitespaceEnd = false;

  function createNewPassword() {
    $scope.submitted = true;
    //clearing all the errors
    $scope.wrongFormatError = false;
    $scope.missMatchError = false;
    $scope.error = false;
    //checks if they pass all the validation
    if(!!$scope.oldPasswordField){
      if(!$scope.password_old.length){
        $scope.error = true;
      }
    }
    if (checkPasswordFormat()) {
      if (!checkMatchPassword()) {
        //message that the new password didn't match
        $scope.missMatchError = true;
        $scope.error = true;
      }
    }
    else {
      //message about the required password format
      $scope.wrongFormatError = true;
      $scope.error = true;
    }
    if (!$scope.error) {
      if(!!$scope.oldPasswordField){
        singleUser.put({
            id: 'me'
          },
          {
            old_password: $scope.password_old,
            password: $scope.password_new,
            password_confirm: $scope.password_confirm
          },
          function () {
            $uibModalInstance.close();
            toastr.success("User's password successfully changed");
          },
          function (error) {
            globalFunc.objectErrorMessage(error);
          })
        .$promise
        .then($timeout(function (resource) {
            $rootScope.$broadcast('reloadAuditData');
        }, 3000));
      } else {
        // If not admin, use this endpoint
        if (globalFunc.checkIfUserHasSupplierWebAdminRole($rootScope.currentUser)) {
          supplierWebUserResetPassword.put({
              id: $scope.profile._id
            }, {
              password: $scope.password_new,
              password_confirm: $scope.password_confirm,
              send_email : $scope.send_email
            },
            function () {
              $uibModalInstance.close();
              $translate("profiles.userPasswordChangedSuccessfully").then(function (trans) {
                toastr.success(trans);
              });
            },
            function (error) {
              globalFunc.objectErrorMessage(error);
            })
            .$promise
            .then($timeout(function (resource) {
                $rootScope.$broadcast('reloadAuditData');
            }, 3000));
        } else {
          // If admin, use this endpoint
          singleUser.put({
              id: $scope.profile._id
            }, {
              password: $scope.password_new,
              password_confirm: $scope.password_confirm,
              send_email : $scope.send_email
            },
            function () {
              $uibModalInstance.close();
              $translate("profiles.userPasswordChangedSuccessfully").then(function (trans) {
                toastr.success(trans);
              });
            },
            function (error) {
              globalFunc.objectErrorMessage(error);
            })
            .$promise
            .then($timeout(function (resource) {
                $rootScope.$broadcast('reloadAuditData');
            }, 3000));
        }
      }
    }
    else{
      toastr.error("Please fill in the required fields");
    }
  }

  function checkPasswordFormat() {
    var reg = new RegExp($scope.regexPassword);
    return reg.test($scope.password_new);
  }

  function checkMatchPassword() {
    return $scope.password_new === $scope.password_confirm;
  }

  function closeModal() {
    $uibModalInstance.close();
  }

  function isV2FeatureEnabled() {
    return !!$rootScope.isV2Enabled;
  }

  function loadPasswordSettings() {
    adminSettings.get(
      {},
      function(resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.passwordSettings = resource.content.data.config.password;
          $scope.regexPassword = globalFunc.generateRegexPassword($scope.passwordSettings);
        }
      },
      function(error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function checkFields(value, field) {
    var reg;
    var test = false;
    switch (field) {
      case 'MULTICASE':
        reg = new RegExp("(?=.*[a-z])(?=.*[A-Z])")
        test = reg.test(value);
        break;
      case 'NUMBERS':
        reg = new RegExp("(?=.*[0-9])")
        test = reg.test(value);
        break;
      case 'SYMBOLS':
        reg = new RegExp("(?=.*[@#$!%*?&])")
        test = reg.test(value);
        break;
      case 'WHITESPACESTART':
        reg = new RegExp("^\\s")
        test = reg.test(value) ? false : true;   
        break;
      case 'WHITESPACEEND':
        reg = new RegExp("\\s$")
        test = reg.test(value) ? false : true;
        break;
    }
    return test;
  }

  loadPasswordSettings();
  $scope.$watch('changePassword.password_new.$viewValue', function (newValue, oldValue) {
    $scope.isMulticased = checkFields(newValue, 'MULTICASE')
    $scope.isNumbered = checkFields(newValue, 'NUMBERS')
    $scope.isSymboled = checkFields(newValue, 'SYMBOLS')
    $scope.isWhitespaceStart = checkFields(newValue, 'WHITESPACESTART')
    $scope.isWhitespaceEnd = checkFields(newValue, 'WHITESPACEEND')
  });
}

passwordResetModalCtrl.$inject = ['$scope', 'profile', '$uibModalInstance', 'toastr', 'singleUser',
  'globalFunc', 'supplierWebUserResetPassword', '$rootScope', '$timeout', '$translate', 'adminSettings'];

angular.module('metabuyer')
  .controller('passwordResetModalCtrl', passwordResetModalCtrl);
