'use strict';
/**
 * @name passwordResetCtrl
 * @desc Controller for Users to enter their new password after it has been reset
 */
function passwordResetCtrl($scope, $rootScope, passwordUpdate, $state, $location, toastr, ManageLocalStorage, $auth, $translate, globalFunc, adminSettings) {
  $scope.updatePassword = updatePassword;

  $scope.isChangePasswordClicked = false;
  $scope.errorFlag = false;
  $scope.missMatchError = false;
  $scope.wrongFormatError = false;
  $scope.isMulticasedPass = false;
  $scope.isNumberedPass = false;
  $scope.isSymboledPass = false;
  $scope.isMulticasedConfPass = false;
  $scope.isNumberedConfPass = false;
  $scope.isSymboledConfPass = false;
  $scope.isWhitespaceStart = false;
  $scope.isWhitespaceEnd = false;

  $scope.logout = logout;
  $scope.isV2FeatureEnabled = false;

  //TODO: use the services of metabuyer (Ahmed Saleh, 31/5/2016)
  function logout() {
    ManageLocalStorage.clearLocalStorage();
    window.localStorage.removeItem('satellizer_token');
    $auth.logout();
    $rootScope.currentUser = null;
    $state.go('login');
    toastr.clear();
  }

  function updatePassword() {
    //clearing all the errors
    $scope.wrongFormatError = false;
    $scope.missMatchError = false;
    $scope.errorFlag = false;
    //checks if they pass all the validation
    if (checkPasswordFormat()) {
      if (!checkMatchPassword()) {
        //message that the new password didn't match
        $scope.missMatchError = true;
        $scope.errorFlag = true;
      }
    }
    else {
      //message about the required password format
      $scope.wrongFormatError = true;
      $scope.errorFlag = true;
    }
    if (!$scope.errorFlag) {
      passwordUpdate.put({
          password: $scope.password_new,
          password_confirm: $scope.password_confirm

        },
        function (success) {
          if (!!$rootScope.redirectUrl) {
            var tempUrl  = angular.copy($rootScope.redirectUrl);
            // If the redirect url is supplier web dashboard
            if (tempUrl === '/user/supplier-web/dashboard') {
              $state.go('main.user.suppliersWeb.dashboard');
            }
            else {
              $location.url(tempUrl);
            }
          } else {
            $state.go('main.user.mainMenu.waiting-on-you');
          }
          toastr.clear();
          $translate('resetPassword.success').then(function (trans) {
            toastr.success(trans);
          });
        },
        function (error) {
          if (!!error && !!error.data) {
            if (parseInt(error.data.http_code) === 400) {
              toastr.error((!!error.data.message) ? error.data.message :
                (!!error.data.content && !!error.data.content.error) ? error.data.content.error : 'Failed to change password');
              $scope.errorFlag = true;
            }
          }
        });
    }
    else {

    }

  }

  function checkPasswordFormat() {
    var reg = new RegExp($scope.regexPassword);
    return reg.test($scope.password_new);
  }

  function checkMatchPassword() {
    return $scope.password_new === $scope.password_confirm;
  }

  function loadPasswordSettings() {
    adminSettings.get(
      {},
      function(resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.passwordSettings = resource.content.data.config.password;
          $scope.regexPassword = globalFunc.generateRegexPassword($scope.passwordSettings);
        }
      },
      function(error) {
        $scope.passwordSettings = error.data.passwordSettings;
        $scope.isV2FeatureEnabled = error.data.toggledV2;
        $scope.regexPassword = globalFunc.generateRegexPassword($scope.passwordSettings);
        $scope.$watch('loginForm.password_new.$viewValue', function (newValue, oldValue) {
          $scope.isMulticasedPass = checkFields(newValue, 'MULTICASE')
          $scope.isNumberedPass = checkFields(newValue, 'NUMBERS')
          $scope.isSymboledPass = checkFields(newValue, 'SYMBOLS')
          $scope.isWhitespaceStart = checkFields(newValue, 'WHITESPACESTART')
          $scope.isWhitespaceEnd = checkFields(newValue, 'WHITESPACEEND')
        });
      }
    )
  }

  function checkFields(value, field) {
    var reg;
    var test = false;
    switch (field) {
      case 'MULTICASE':
        reg = new RegExp("(?=.*[a-z])(?=.*[A-Z])")
        test = reg.test(value);
        break;
      case 'NUMBERS':
        reg = new RegExp("(?=.*[0-9])")
        test = reg.test(value);
        break;
      case 'SYMBOLS':
        reg = new RegExp("(?=.*[@#$!%*?&])")
        test = reg.test(value);
        break;
      case 'WHITESPACESTART':
        reg = new RegExp("^\\s")
        test = reg.test(value) ? false : true; 
        break;
      case 'WHITESPACEEND':
        reg = new RegExp("\\s$")
        test = reg.test(value) ? false : true;
        break;
    }
    return test;
  }
  
  loadPasswordSettings()
}

passwordResetCtrl.$inject = ['$scope', '$rootScope', 'passwordUpdate', '$state', '$location', 'toastr',
  'ManageLocalStorage', '$auth', '$translate', 'globalFunc', 'adminSettings'];

angular
  .module('metabuyer')
  .controller('passwordResetCtrl', passwordResetCtrl);
