'use strict';

function temporaryPasswordResetModalCtrl($scope, profile, $uibModalInstance, toastr, singleUser, globalFunc,
                                supplierWebUserResetPassword, $rootScope) {

  $scope.createNewTemporaryPassword = createNewTemporaryPassword;
  $scope.closeModal = closeModal;
  $scope.error = false;
  $scope.missMatchError = false;
  $scope.wrongFormatError = false;
  $scope.profile = profile;

  function createNewTemporaryPassword() {
    $scope.submitted = true;
    //clearing all the errors
    $scope.wrongFormatError = false;
    $scope.missMatchError = false;
    $scope.error = false;

    if (!checkPasswordFormat()) {
      //message about the required password format
      $scope.wrongFormatError = true;
      $scope.error = true;
    }

    if (!$scope.error) {
      // If not admin, use this endpoint
      if (globalFunc.checkIfUserHasSupplierWebAdminRole($rootScope.currentUser)) {
        supplierWebUserResetPassword.put({
            id: $scope.profile._id
          }, {
            password: $scope.password_new,
            password_confirm: $scope.password_confirm,
            send_email : $scope.send_email
          },
          function () {
            $uibModalInstance.close();
            toastr.success("User's password successfully changed");
            // $scope.data.reset = false;
          },
          function (error) {
            globalFunc.objectErrorMessage(error);
          });
      } else {
        singleUser.put({
            id: $scope.profile._id
          }, {
            password: $scope.password_new,
            password_confirm: $scope.password_new,
            send_email: $scope.send_email,
            is_temporary_password: true
          },
          function () {
            $uibModalInstance.close();
            toastr.success("User's password successfully changed");
            // $scope.data.reset = false;
          },
          function (error) {
            globalFunc.objectErrorMessage(error);
          });
      }
    }
    else{
      toastr.error("Please fill in the required fields");
    }
  }

  function checkPasswordFormat() {
    var letter = /[a-z]/;
    var capitalLetter = /[A-Z]/;
    var number = /[0-9]/;
    return (letter.test($scope.password_new) && number.test($scope.password_new) &&
      $scope.password_new.length > 7 && capitalLetter.test($scope.password_new));
  }

  function closeModal() {
    $uibModalInstance.close();
  }

}

temporaryPasswordResetModalCtrl.$inject = ['$scope', 'profile', '$uibModalInstance', 'toastr', 'singleUser',
  'globalFunc', 'supplierWebUserResetPassword', '$rootScope'];

angular.module('metabuyer')
  .controller('temporaryPasswordResetModalCtrl', temporaryPasswordResetModalCtrl);
